import type { FileArea, FileCategory, FolderUrn } from '@mntn-dev/domain-types'
import { UseUploadWidget as baseHook } from '@mntn-dev/files-cloudinary-client'
import {
  MaxFileSize,
  type ParamPrepHandler,
  type SuccessEvent,
  type UploadHookOptions,
  type UploadWidgetEventHandler,
  type UploadWidgetEventHandlers,
  fileConfigurations,
} from '@mntn-dev/files-shared'
import type { AfterUploadHandler } from './after-upload-event.ts'
import type { ReactFilesContext } from './context.ts'
import { createSuccessHandler } from './handle-success.ts'
import { prepParams } from './prep-params.ts'

export type UseUploadWidgetEventHandlers = Partial<
  Omit<UploadWidgetEventHandlers, 'onSuccess'>
>

export type UseUploadWidgetProps = {
  category?: FileCategory
  fileArea: FileArea
  folderUrn: FolderUrn
  onAfterUpload?: AfterUploadHandler
  options?: Omit<UploadHookOptions, 'cloudName' | 'folder' | 'showPoweredBy'>
} & UseUploadWidgetEventHandlers

export const createUseUploadWidget =
  (context: ReactFilesContext) =>
  ({
    fileArea,
    category,
    folderUrn,
    onAfterUpload,
    options,
    ...eventHandlers
  }: UseUploadWidgetProps) => {
    const trpcUtils = context.trpcReactClient.useUtils()
    const trpcClient = trpcUtils.client

    const successHandler: UploadWidgetEventHandler<SuccessEvent> =
      createSuccessHandler(
        fileArea,
        folderUrn,
        trpcClient,
        category,
        onAfterUpload
      )

    const paramPrepHandler: ParamPrepHandler = prepParams(
      fileArea,
      trpcClient,
      context.apiKey
    )

    const uploadHookOptions: UploadHookOptions = {
      styles: {
        palette: {
          window: '#042138',
          sourceBg: '#042138',
          windowBorder: '#707B84',
          tabIcon: '#FFFFFF',
          inactiveTabIcon: '#546B96',
          menuIcons: '#41C0FF',
          link: '#41C0FF',
          action: '#41C0FF',
          inProgress: '#41C0FF',
          complete: '#1FC38F',
          error: '#FF3333',
          textDark: '#000000',
          textLight: '#FFFFFF',
        },
        fonts: {
          default: null,
          'Inter, sans-serif': {
            url: 'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
            active: true,
          },
        },
      },
      maxFileSize: MaxFileSize,
      ...options,
      cloudName: context.cloudName,
      folder: fileConfigurations[fileArea].folder,
      showPoweredBy: false,
    }

    const baseHookResults = baseHook({
      options: uploadHookOptions,
      eventHandlers: { onSuccess: successHandler, ...eventHandlers },
      callbackHandlers: {
        onParamPrep: paramPrepHandler,
      },
    })

    return baseHookResults
  }
